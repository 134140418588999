export function treatUsImages(metadata) {
  const sequenceOfUltrasoundRegions = metadata.SequenceOfUltrasoundRegions;

  if (!sequenceOfUltrasoundRegions) {
    return false;
  } else {
    let hasDca = false;
    for (let j = 0; j <= sequenceOfUltrasoundRegions.length; j++) {
      const us = sequenceOfUltrasoundRegions[j];
      if (us) {
        const dopplerCorrectionAngle = us.DopplerCorrectionAngle;
        if (dopplerCorrectionAngle !== undefined) {
          hasDca = true;
        }
      }
    }
    if (!hasDca) {
      return false;
    } else {
      return true;
    }
  }
}
