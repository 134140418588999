import { redux } from '@ohif/core';
const { setLayout } = redux.actions;

/**
 * Update the current layout with a simple Cornerstone one
 *
 * @return {Promise<void>}
 */
const setCornerstoneLayout = () => {
  return new Promise(resolve => {
    localStorage.setItem('isMprActive', 'disabled');
    //
    const layout = {
      numRows: 1,
      numColumns: 1,
      viewports: [{ plugin: 'cornerstone' }],
    };

    const action = setLayout(layout);

    window.store.dispatch(action);

    // Introduce a short delay to ensure the layout change is fully propagated
    setTimeout(resolve, 1); // Adjust the delay as needed
  });
};

export default setCornerstoneLayout;
